<template>
  <v-hover v-slot="{ hover }" class="flex d-flex flex-column">
    <v-card
      :elevation="hover ? 9 : 0"
      class="rounded-xl"
      :to="articlesPath + item.uid"
    >
      <router-link :to="articlesPath + item.uid">
        <v-img
          :src="item.data.image.url"
          v-if="item.data.image && item.data.image.url"
          class="rounded-xl"
        />
        <v-img src="" class="rounded-xl" v-else />
      </router-link>
      <v-card-text class="pa-4 blog-item-info">
        <h3 class="blog-item-title title mb-4" :title="item.data.title">
          {{ item.data.title }}
        </h3>
        <div class="blog-item-intro mb-4" :title="item.data.intro">
          {{ item.data.intro }}
        </div>
        <div>
          <span class="grey--text" v-if="item.first_publication_date">
            {{ item.first_publication_date.substring(0, 10) }}
          </span>
          <span
            class="ml-4 grey--text d-inline-block"
            v-if="item.data.reading_time"
          >
            <v-icon small>mdi-clock-outline</v-icon>
            {{ item.data.reading_time }}m
          </span>
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  name: "ArticleItem",
  props: { item: Object },
  data: () => ({}),
  computed: {
    topicsPath() {
      return getTopicsPath(this.$i18n.locale);
    },
    articlesPath() {
      return getArticlesPath(this.$i18n.locale);
    },
  },
};
</script>

<style scoped>
.blog-item-info {
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.blog-item-title {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
}
.blog-item-title:hover {
}

.blog-item-intro {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>