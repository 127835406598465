<template>
  <v-main class="grey lighten-5" transition="fade-transition">
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="grey lighten-2">
      <v-container class="py-8 py-md-16" v-if="!isLoading">
        <v-card
          elevation="0"
          class="rounded-xl transparent"
          :to="articlesPath + latestArticles[0].uid"
        >
          <v-row>
            <v-col cols="12" md="6" class="pa-8 pa-md-0">
              <router-link :to="articlesPath + latestArticles[0].uid">
                <v-img
                  :src="latestArticles[0].data.image.url"
                  v-if="
                    latestArticles[0].data.image &&
                    latestArticles[0].data.image.url
                  "
                  class="rounded-xl"
                />
                <v-img src="" class="rounded-xl" v-else />
              </router-link>
            </v-col>
            <v-col cols="12" md="6" class="pa-8 pt-0 pt-md-8">
              <h2 class="text-h4 ma-0 black--text mb-8">
                {{ latestArticles[0].data.title }}
              </h2>
              <div class="title black--text mb-4">
                {{ latestArticles[0].data.intro }}
              </div>
              <div>
                <span
                  class="grey--text"
                  v-if="latestArticles[0].first_publication_date"
                >
                  {{
                    latestArticles[0].first_publication_date.substring(0, 10)
                  }}
                </span>
                <span
                  class="ml-4 grey--text d-inline-block"
                  v-if="latestArticles[0].data.reading_time"
                >
                  <v-icon small>mdi-clock-outline</v-icon>
                  {{ latestArticles[0].data.reading_time }}m
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
    <v-container class="pt-8 pb-16" v-if="!isLoading">
      <v-row>
        <v-col cols="12" md="12" class="pa-5">
          <v-row>
            <v-col>
              <router-link
                :to="articlesPath"
                class="
                  text-h5 text-md-h4 text-decoration-none
                  black--text
                  mr-3
                  d-inline-block
                  my-0
                "
              >
                {{ $t("latest_articles") }}
              </router-link>
            </v-col>
            <v-col class="text-right"> </v-col>
          </v-row>

          <v-row class="mt-4">
            <template v-for="(item, index) in latestArticles">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                :key="item.uid"
                v-if="index >= 1 && index <= 10"
              >
                <ArticleItem :item="item" />
              </v-col>
            </template>
          </v-row>

          <v-btn
            :to="articlesPath"
            color="black"
            large
            rounded
            block
            depressed
            class="white--text my-16 text-decoration-none"
          >
            {{ $t("see_more") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import ArticleItem from "../components/ArticleItem";
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  name: "Home",
  title: window.vm.$i18n.t("blog"),
  props: {
    topics: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: true,
    articles: [],
  }),
  components: { ArticleItem },
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
  },
  computed: {
    locale() {
      return "es-es"; //this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
    topicsPath() {
      return getTopicsPath(this.$i18n.locale);
    },
    articlesPath() {
      return getArticlesPath(this.$i18n.locale);
    },
  },
  methods: {
    getTopicArticles(tag) {
      const articles = this.articlesOrdered.filter(
        (item) => item.tags[0] === tag
      );
      return articles.sortBy("position");
    },
    async loadContent() {
      this.isLoading = true;
      await this.getLatestArticles();
      this.isLoading = false;
    },
    async getLatestArticles() {
      const document = await this.$prismic.client.query(
        [this.$prismic.Predicates.at("document.type", "articles")],
        {
          orderings: "[document.first_publication_date desc]",
          lang: this.locale,
          pageSize: 10,
        }
      );
      this.latestArticles = document.results;
    },
  },
  mounted() {
    this.loadContent();
  },
  beforeRouteUpdate(to, from, next) {
    this.loadContent();
    next();
  },
};
</script>

<style scoped>
.hero {
  background: radial-gradient(
    circle,
    rgba(255, 178, 77, 0.8) 0%,
    rgba(255, 100, 25, 1) 100%
  );
}
</style>